import { Box } from '@mui/material';
import { useEffect, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import Footer from 'src/features/elements/footer';
import FooterMobile from 'src/features/mobile/footerMobile';
import Header from './Header';

export default function MainLayout() {
  const { pathname } = useLocation();

  const isHome = pathname === '/';

  const [isSmallScreen, setIsSmallScreen] = useState(false);

  useEffect(() => {
    const mediaQuery = window.matchMedia('(max-width: 500px)');

    const handleResize = () => {
      setIsSmallScreen(mediaQuery.matches);
    };

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: 1 }}>
      <Header />

      <Box
        component="main"
        sx={{
          flexGrow: 1,
          ...(!isHome && {
            pt: { xs: 8, md: 11 },
          }),
        }}
      >
        <Outlet />
      </Box>
      {isSmallScreen ? <FooterMobile /> : <Footer />}
    </Box>
  );
}
