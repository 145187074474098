import { Stack, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import CustomIconify from 'src/features/components/customIconify';
import { Animate } from 'src/features/global/styles';
import { PATH_EMPRESAS } from 'src/routes/paths';
import { StyledContainer, StyledSocialMidiaIcon, SyledContent, StyledCopyright } from './styles';

// ---------------------------------------------------------------------------

export default function Footer() {
  const light = '#FFF';

  return (
    <Stack>
      <StyledContainer>
        <SyledContent id="Session 1" style={{ alignItems: 'center', textAlign: 'center' }}>
          <Link to="https://emec.mec.gov.br/emec/consulta-cadastro/detalhamento/d96957f455f6405d14c6542552b0f6eb/MTc1NTg=?fbclid=IwAR3VRcpwT2HMq76wYEGJCJCaCcWIKT0TUqzDZh6yXmqrtEkuovp-ZB4WQlc">
            <Animate>
              <img
                src="images/footer/emec.png"
                width={250}
                height={150}
                alt="E-MEC QRCODE"
                style={{ borderRadius: '10px' }}
              />
            </Animate>
          </Link>
        </SyledContent>

        {/* ------------------------------------------------------------------------------------------------------ */}

        <SyledContent id="Session 1" style={{ alignItems: 'center', textAlign: 'center' }}>
          <Typography gutterBottom variant="h5" component="div" align="center" color={light}>
            Redes Sociais
          </Typography>

          <StyledSocialMidiaIcon>
            <Animate>
              <CustomIconify icon="logos:facebook" href="https://www.facebook.com/fasavilhena" />
            </Animate>

            <Animate>
              <CustomIconify
                icon="skill-icons:instagram"
                href="https://www.instagram.com/fasa.vilhena.oficial/"
              />
            </Animate>

            <Animate>
              <CustomIconify
                icon="logos:youtube-icon"
                href="https://www.youtube.com/@fasa-faculdadesantoandrema8265"
              />
            </Animate>
          </StyledSocialMidiaIcon>
        </SyledContent>

        {/* ==================================================== SECAO 2 ============================================== */}

        <SyledContent id="Session 2">
          <Typography gutterBottom variant="h5" mt={5} color={light}>
            Endereço
          </Typography>

          <Typography gutterBottom variant="body1" color={light} width={500}>
            Av. Aníbal Ribeiro Batista, Nº 4077 - <br />
            Res. Orleans, CEP: 76985-784
            <br />
            Vilhena - RO
          </Typography>
        </SyledContent>

        {/* ==================================================== SECAO 3 ============================================== */}

        <SyledContent id="Session 3">
          <Typography gutterBottom variant="h5" mt={5} color={light}>
            Contato
          </Typography>

          <Typography gutterBottom variant="body1" color={light} width={600}>
            Telefone: (69) 98118-0306
          </Typography>
          <Typography gutterBottom variant="body1" color={light} width={600}>
            E-mail: contato@fasaro.edu.br
          </Typography>
        </SyledContent>
      </StyledContainer>

      <StyledCopyright>
        <Typography gutterBottom variant="h6">
          Copyright 2024 - Faculdade Santo André - Todos os direitos reservados
        </Typography>

        <Link to={PATH_EMPRESAS.fullcode} style={{ textDecoration: 'none', color: 'inherit' }}>
          <Animate>
            <Typography gutterBottom variant="h6">
              Desenvolvido por: Full Code Software e Tecnologia
            </Typography>
          </Animate>
        </Link>
      </StyledCopyright>
    </Stack>
  );
}
