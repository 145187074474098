import { Stack, Typography, styled } from '@mui/material';
import { Link } from 'react-router-dom';
import Iconify from 'src/components/iconify';
import { Animate } from 'src/features/global/styles';
import { PATH_CONTACT, PATH_PORTAL_ACADEMICO } from 'src/routes/paths';

// ----------------------------------------------------------------------------

export const StyledHeaderTop = styled(Stack)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-evenly',
  height: '40px',
  overflowX: 'hidden',
  alignItems: 'center',
  backgroundColor: theme.palette.primary.main,
}));

export const StyledContact = styled(Stack)(() => ({
  display: 'flex',
  flexDirection: 'row',
  gap: 10,
  alignContent: 'center',
}));

// ----------------------------------------------------------------------------

export default function HeaderTop() {
  return (
    <StyledHeaderTop>
      <Link to={PATH_CONTACT.whatsApp} style={{ textDecoration: 'none', color: 'inherit' }}>
        <Animate>
          <StyledContact>
            <Iconify icon="logos:whatsapp-icon" />
            <Typography gutterBottom variant="caption" color="white">
              Contato WhatsApp: (69) 98118-0306
            </Typography>
          </StyledContact>
        </Animate>
      </Link>

      <Link to={PATH_PORTAL_ACADEMICO.cbk} style={{ textDecoration: 'none', color: 'inherit' }}>
        <Stack display="flex" gap={3} flexDirection="row">
          <Animate>
            <StyledContact>
              <Iconify icon="tabler:external-link" color="white" />
              <Typography gutterBottom variant="caption" color="white">
                Portal Acadêmico
              </Typography>
            </StyledContact>
          </Animate>
        </Stack>
      </Link>
    </StyledHeaderTop>
  );
}
