import { Typography } from '@mui/material';

interface Props {
  title: string;
  align?: any;
  mb?: number;
}

export default function TextOnPage({ title, align, mb }: Props) {
  return (
    <Typography gutterBottom variant="h4" align={align || 'center'} mt={6} mb={mb || 3}>
      {title}
    </Typography>
  );
}
