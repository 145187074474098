import { StyledTypography } from './styles';

// ----------------------------------------------------------

interface Props {
  children: any;
  style?: any;
}
// ----------------------------------------------------------
export default function ParagraphOnPage({ children, style }: Props) {
  return (
    <StyledTypography
      variant="body1"
      align="justify"
      color="text.secondary"
      gutterBottom
      style={style}
    >
      {children}
    </StyledTypography>
  );
}
