import { Button, Drawer, IconButton, List, Stack, styled } from '@mui/material';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { PATH_CONTACT } from 'src/routes/paths';
import Iconify from '../../../../components/iconify';
import Logo from '../../../../components/logo';
import Scrollbar from '../../../../components/scrollbar';
import { NAV } from '../../../../config-global';
import { NavProps } from '../types';
import NavList from './NavList';

// ----------------------------------------------------------------------

export const StyledAlign = styled(Stack)(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));

// ----------------------------------------------------------------------

export default function NavMobile({ data }: NavProps) {
  const { pathname } = useLocation();

  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (open) {
      handleClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <IconButton
        onClick={handleOpen}
        sx={{
          ml: 1,
        }}
      >
        <Iconify icon="eva:menu-2-fill" />
      </IconButton>

      <Drawer
        open={open}
        onClose={handleClose}
        PaperProps={{
          sx: {
            pb: 5,
            width: NAV.W_BASE,
          },
        }}
      >
        <Scrollbar>
          <StyledAlign>
            <Logo
              sx={{
                mx: 2.5,
                my: 3,
              }}
            />
          </StyledAlign>

          <Stack mt={3}>
            <List component="nav" disablePadding>
              {data.map((link) => (
                <NavList key={link.title} item={link} />
              ))}
            </List>
          </Stack>

          <StyledAlign mt={3} padding={1}>
            <Button variant="contained" href={PATH_CONTACT.whatsApp} fullWidth>
              Fale Conosco
            </Button>
          </StyledAlign>
        </Scrollbar>
      </Drawer>
    </>
  );
}
