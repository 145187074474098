import { Box, Grid, Stack } from '@mui/material';
import { useState } from 'react';
import Image from '../../../components/image';
import Lightbox from '../../../components/lightbox';

// ------------------------------------------------------------------

interface Props {
  length: number;
  folder: string;
  label: string;
}

// ------------------------------------------------------------------

export default function ImageViewer({ length, folder, label }: Props) {
  const [selectedImage, setSelectedImage] = useState<number>(-1);

  const imagesLightbox = [...Array(length)].map((_, index) => ({
    src: `images/news/${folder}/index (${index + 1}).png`,
    title: label,
  }));

  const handleOpenBasic = (imageUrl: string) => {
    const imageIndex = imagesLightbox.findIndex((image) => image.src === imageUrl);
    setSelectedImage(imageIndex);
  };

  const handleCloseBasic = () => {
    setSelectedImage(-1);
  };

  return (
    <>
      <Stack width="100%">
        <Grid container spacing={3} direction="row" justifyContent="center" alignItems="center">
          <Grid item xs={10} md={9}>
            <Box
              gap={1}
              display="grid"
              gridTemplateColumns={{
                xs: 'repeat(2, 1fr)',
                sm: 'repeat(3, 1fr)',
                md: 'repeat(4, 1fr)',
              }}
            >
              {imagesLightbox.map((img) => (
                <Image
                  key={img.src}
                  alt={img.src}
                  src={img.src}
                  ratio="1/1"
                  onClick={() => handleOpenBasic(img.src)}
                  sx={{
                    borderRadius: 1,
                    cursor: 'pointer',
                  }}
                />
              ))}
            </Box>
          </Grid>
        </Grid>
      </Stack>

      <Lightbox
        index={selectedImage}
        open={selectedImage >= 0}
        close={handleCloseBasic}
        slides={[
          ...imagesLightbox,
          // {
          //   type: 'video',
          //   width: 1280,
          //   height: 720,
          //   poster:
          //     'https://commondatastorage.googleapis.com/gtv-videos-bucket/sample/images/BigBuckBunny.jpg',
          //   sources: [
          //     {
          //       src: 'https://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4',
          //       type: 'video/mp4',
          //     },
          //   ],
          // },
        ]}
      />
    </>
  );
}
