import { Typography, styled } from '@mui/material';

// --------------------------------------------------------------

export const StyledTypography = styled(Typography)(() => ({
  padding: '0px 100px',
  marginBottom: 6,

  '@media (max-width: 750px)': {
    padding: '0px 30px',
  },
}));
