import { Stack } from '@mui/material';
import { NavProps } from '../types';
import NavList from './NavList';

// ----------------------------------------------------------------------

export default function NavDesktop({ isOffset, data }: NavProps) {
  return (
    <Stack component="div" direction="row" spacing={2}>
      {data.map((link) => (
        <NavList key={link.title} item={link} isOffset={isOffset} />
      ))}
    </Stack>
  );
}
