import { Box, Stack } from '@mui/material';
import News from 'src/features/components/news';

// ----------------------------------------------------------------------------

export const newsContent = [
  {
    title: 'Aula Prática de Citologia',
    course: 'Enfermagem',
    date: '25/05/2024',
    folder: 'citologia',
    lenghtPic: 6,
  },
];

// ----------------------------------------------------------------------------

export default function AulaCitologia() {
  return (
    <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
      <Stack
        margin={8}
        maxWidth="1140px"
        width="100%"
        display="flex"
        justifyContent="center"
        alignItems="center"
        alignContent="center"
        alignSelf="center"
      >
        <News content={newsContent}>
          A aula prática de Citologia Humana é uma experiência enriquecedora para os estudantes da
          área de saúde. Ao observar células humanas ao microscópio, preparar lâminas, identificar
          organelas e alterações celulares, os estudantes desenvolvem habilidades práticas
          essenciais para sua formação. Além disso, a correlação clínica das observações citológicas
          os prepara para a aplicação desse conhecimento na prática profissional. Essa aula prática
          complementa o aprendizado teórico e proporciona uma compreensão mais aprofundada da
          estrutura e função das células humanas, contribuindo para a formação de profissionais
          competentes e bem preparados.
        </News>
      </Stack>
    </Box>
  );
}
