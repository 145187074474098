import { Stack, Typography } from '@mui/material';
import ParagraphOnPage from 'src/features/components/paragrathOnPage';
import TextOnPage from 'src/features/components/titlePage';
import ImageViewer from '../lightbox';

// ----------------------------------------------------------------------------

interface Props {
  children: any;
  content: {
    title: string;
    course: string;
    cover?: string;
    date: string;
    folder: string;
    lenghtPic: number;
  }[];
}

// ----------------------------------------------------------------------------

export default function News({ children, content }: Props) {
  return (
    <Stack>
      {content.map((data) => (
        <>
          <Stack>
            <TextOnPage title={data.title} align="center" mb={1} />
            <Typography variant="subtitle2" align="center" color="text.secondary">
              <strong>Publicação: </strong> Departamento de publicidade / <strong>Curso: </strong>
              {data.course} / <strong>Data: </strong> {data.date}
            </Typography>
          </Stack>
          <Stack
            style={{
              width: '100%',
              height: 'auto',
              marginTop: '30px',
              marginBottom: '30px',
              borderRadius: '10px solid',
            }}
          >
            {data.cover === null && (
              <img
                src={data.cover}
                alt="Capa Matéria"
                style={{ width: '100%', height: 'auto', maxHeight: '600px' }}
              />
            )}
          </Stack>
          <Stack>
            <ParagraphOnPage style={{ padding: '0px' }}>{children}</ParagraphOnPage>
          </Stack>

          <Stack marginTop={5} marginBottom={2}>
            <ImageViewer folder={data.folder} length={data.lenghtPic} label={data.title} />,
          </Stack>
        </>
      ))}
    </Stack>
  );
}
