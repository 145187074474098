import { Stack, styled } from '@mui/material';

// --------------------------------------------------------------

export const StyledContainer = styled(Stack)(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: '#222222',
  flexWrap: 'wrap',

  '@media (min-width: 600px)': {
    flexDirection: 'row',
  },
}));

export const SyledContent = styled(Stack)(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  flex: 1,
  height: '300px',
  minWidth: '150px',
  overflow: 'hidden',
  flexWrap: 'wrap',
}));

export const StyledSocialMidiaIcon = styled(Stack)(() => ({
  display: 'flex',
  flexDirection: 'row',
  gap: 10,
  flexWrap: 'wrap',
}));

export const StyledCopyright = styled(Stack)(() => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-evenly',
  height: '50px',
  overflow: 'hidden',
  alignItems: 'center',
  padding: '20px',
  flexWrap: 'wrap',
  alignContent: 'center',
}));
