import { AppBar, Box, BoxProps, Toolbar } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useRef } from 'react';
import HeaderTop from 'src/features/components/headerTop';
import Logo from '../../components/logo';
import { HEADER } from '../../config-global';
import useOffSetTop from '../../hooks/useOffSetTop';
import useResponsive from '../../hooks/useResponsive';
import navConfig from './nav/config-navigation';
import NavDesktop from './nav/desktop';
import NavMobile from './nav/mobile';

// --------------------------------------------------------

export default function Header() {
  const carouselRef = useRef(null);

  const theme = useTheme();

  const isDesktop = useResponsive('up', 'md');

  const isOffset = useOffSetTop(HEADER.H_MAIN_DESKTOP);
  const isOffsetMobile = useOffSetTop(HEADER.H_MAIN_MOBILE);

  return (
    <AppBar ref={carouselRef} color="default" sx={{ boxShadow: 10 }}>
      <HeaderTop />

      {isDesktop && (
        <Toolbar
          sx={{
            height: {
              xs: HEADER.H_MOBILE,
              md: HEADER.H_MAIN_DESKTOP,
              justifyContent: 'space-around',
            },
            transition: theme.transitions.create(['height', 'background-color'], {
              easing: theme.transitions.easing.easeInOut,
              duration: theme.transitions.duration.shorter,
            }),
          }}
        >
          <Logo />
          <NavDesktop isOffset={isOffset} data={navConfig} />
        </Toolbar>
      )}

      {!isDesktop && (
        <Toolbar
          sx={{
            height: '60px',
            display: 'flex',
            justifyContent: 'space-around',
          }}
        >
          <Logo sx={{ width: '150px', height: '100' }} />
          <NavMobile isOffset={isOffsetMobile} data={navConfig} />
        </Toolbar>
      )}

      {isOffset && <Shadow />}
    </AppBar>
  );
}

// ----------------------------------------------------------------------

function Shadow({ sx, ...other }: BoxProps) {
  return (
    <Box
      sx={{
        left: 0,
        right: 0,
        bottom: 0,
        height: 24,
        zIndex: -1,
        m: 'auto',
        borderRadius: '50%',
        position: 'absolute',
        width: `calc(100% - 48px)`,
        boxShadow: (theme) => theme.customShadows.z8,
        ...sx,
      }}
      {...other}
    />
  );
}
